.EventDetailAddToCalendar .chq-atc {
    width: 100%;
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.EventDetailAddToCalendar .chq-atc--button {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    padding: 0px 6px 0px 0px;
    background-color: #F44434;
    box-shadow: 0px 0px 30px #F44434;
    height: 48px;
    width: 100%;
    border-radius: 0;
}

.EventDetailAddToCalendar .chq-atc--button img {
    padding-top: 2px;
}

.EventDetailAddToCalendar .chq-atc--button:focus {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), #F44434 !important;
    background-color: #F44434 !important;
}

.EventDetailAddToCalendar .chq-atc--button:hover {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), #F44434 !important;
    background-color: #F44434 !important;
}

.EventDetailAddToCalendar .chq-atc--button>svg {
    display: none;
}

.EventDetailAddToCalendar .chq-atc--dropdown {
    background-color: white;
    border-radius: 5px;
    border: 1px solid #eaeaea;
    box-shadow: .5px .5px 3px rgba(0, 0, 0, .2);
    box-sizing: border-box;
    position: absolute;
    top: -180px !important;
    right:0;
    text-align: left;
    white-space: nowrap;
    width: auto;
    min-width: 200px;
    z-index: 999;
}

.EventDetailAddToCalendar .chq-atc--dropdown a {
    color: black;
    display: block;
    padding: 8px 15px;
    text-decoration: none;
  }